/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

a {
    text-decoration: none;
    color: white;
    text-align: center;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
    color: #AB99D0 !important;
}

.mat-form-field-label {
    color: #AB99D0;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    border-radius: 10px !important;
    color: #AB99D0 !important;
}
/* .mat-form-field-appearance-outline .mat-form-field-outline {
    color: transparent !important;
} */
.mat-form-field-appearance-outline .mat-form-field-outline {
    color: #AB99D0; /* Cambia el color del borde a rojo */
  }


.mat-form-field-appearance-legacy .mat-form-field-underline{
    background: #AB99D0;
}

.mat-form-field-empty.mat-form-field-label .mat-form-field-can-float.mat-form-field-should-float.mat-form-fiel-label {
    color: #AB99D0 !important;
}


.mat-form-field .mat-form-field-flex {
    height: 40px !important;
}

.input-440-32 {
    .mat-form-field-appearance-outline .mat-form-field-label {
        top: 1.9em !important;
    }
    input.mat-input-element {
        margin-bottom: 4px;
    }
    .mat-form-field-appearance-outline .mat-form-field-suffix {
        top: 0.1em !important;
    }
    .mat-form-field .mat-form-field-flex {
        height: 40px !important;
    }

    .mat-form-field-infix {
        border-top: none !important;
        width: 100%;
        /* width: 280px; */
    }
    .mat-form-field-appearance-outline .mat-form-field-outline {
        background-color: white !important;
        //border-radius: 30px !important;
        border: transparent !important;
        border: 2px solid #b61b93 !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: transparent !important;
    }
    .mat-form-field-label {
        color: #b61b93;
        font-size: 14px;
        letter-spacing: -0.28px;
    }
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 0px;
    /* color: #ff00cb; */
}

.mat-button-toggle-checked {
    background: linear-gradient(223.85deg, #dd5263 0%, #b61b93 62.21%, #732286 95.7%);
    color: white !important;
    font-weight: 500;
    .mat-form-field-appearance-outline .mat-form-field-outline {
        border: none !important;
    }
    /* width: 100%; */
}

.mat-button-toggle-appearance-standard {
    color: #ff00cb;
    font-weight: 500;
}

.mat-button-toggle-button {
    height: 35px !important;
    border: solid 1px #ff00cb !important;
    border-radius: 16px;
}

.mat-dialog-container{
    border-radius: 15px !important;
}

.tb-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    z-index: 99999;
    border: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    cursor: wait;
    position: fixed;
    background-color: transparent;
    mat-progress-spinner {
        margin: 15% auto;
    }
    &.mat-progress-spinner circle,
    .mat-spinner circle {
        stroke: #ff00cb;
    }
}